
body  
{   
    margin: 0;  
    padding: 0;  
    background-color:#e3e9f8;  
    font-family: 'Arial'; 
   /* background-image: url('delete2.PNG');
    background-size: cover;*/
}  
.login{   
    overflow: hidden;  
    margin: auto;  
    margin: 20 0 0 450px;  
    padding: 80px;  
    background: #7692dd;  
    border-radius: 15px ;           
}
/* h2{  
    text-align: center;  
    color: #ffffff;  
    padding: 20px;  
}  */
label{  
    color: #ffffff;  
    font-size: 17px;  
}


#Uname{  
    width: 340px;  
    height: 40px;  
    border: none;  
    border-radius: 3px;  
    padding-left: 8px;  
} 
#Pass{  
    width: 340px;  
    height: 40px;  
    border: none;  
    border-radius: 3px;  
    padding-left: 8px;  
      
}

#log{  
    width: 210px;  
    height: 40px;  
    border: none;  
    border-radius: 17px;  
    padding-left: 7px;  
    color: black;  
    font-size: 25px;
} 
#log:hover {
    background-color: #2a45af;
    color:white
}

/* body,html {
    margin: 0;
    font-family: sans-serif;
  } */
  
  ul {
    padding: 0;
    width: 100%;
  }
  
  li {
    display: inline;
  }
  
  a {
    outline: none;
    text-decoration: none;
    display: inline-block;
    width: 19.5%;
    margin-right: 0.625%;
    text-align: center;
    line-height: 3;
    color: black;
  }
  
  /* li:last-child a {
    margin-right: 0;
  } */
  
  a:link, a:visited, a:focus {
    background: #dce3ec;
    border-radius: 0px;
    color:black;
    border-color: black;
    border-style: double;
  }
  
  /* a:hover {
    background: orange;
    border-color: white;
  }
  
  a:active {
    background: red;
    color: white;
  } */